
import { Container, Row, Col } from 'react-bootstrap';
import './SecondaryActions.css';

import { Facebook, Linkedin, Instagram, Youtube, Pinterest, Tiktok } from 'react-bootstrap-icons';

function SecondaryActions(props) {
    return (
        <Container fluid className="SecondaryActions">
            <Row className="justify-content-center pb-3 px-3">
                <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="https://pl-pl.facebook.com/pages/category/Veterinarian/Przychodnia-Weterynaryjna-AWET-112978742112666/">
                    <div className="ikona">
                        <img src="./facebook1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                    </div>
                    </a>
                </Col>
                <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="#">
                    <div className="ikona">
                        <img src="./instagram1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>    
                    </a>
                </Col>
                <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="#">
                    <div className="ikona">
                        <img src="./linkedin1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="#">
                    <div className="ikona">
                        <img src="./youtube1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="#">
                    <div className="ikona">
                        <img src="./pinterest1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="#">
                    <div className="ikona">
                        <img src="./tiktok1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}

export default SecondaryActions;